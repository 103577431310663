module.exports = [{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://e639f9782ce74efdb27edef65fd9091e@o510873.ingest.sentry.io/5607256","environment":"staging","sampleRate":1},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Investing | Managed Funds | KiwiSaver | Fisher Funds","short_name":"Fisher Funds","start_url":"/","background_color":"#00447C","theme_color":"#00447C","display":"minimal-ui","icon":"src/assets/images/icon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8a7f2319ec52ff790d0af43b795e8dde"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | Fisher Funds","language":"en","openGraph":{"site_name":"Fisher Funds","locale":"en_NZ"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
